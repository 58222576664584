/**
 * This checks if the passed tag exists more than once in the dom
 * @function fetchMetaTags
 * @param {Array<string>} tag meta tags
 * @return {Array<string>} returns found elements content
 */
export default function fetchMetaTags(tags: Array<string>): Array<string> {
    const tagContents: Array<string> = [];
    const mapQueryString = tags.reduce(
        (prevTag, currTag) =>
            (prevTag += `meta[name='${currTag}'], meta[property='${currTag}']`),
        '',
    );
    const matches = document.querySelectorAll(mapQueryString);
    matches.length > 0 &&
        matches.forEach((match) => {
            const matchContent = match.getAttribute('content');
            matchContent && tagContents.push(matchContent);
        });
    return tagContents;
}
