export const BANANA = 'banana'; // Just testing tree shaking :D

export const CUSTOM_ALLOW_LIST =
    (process.env.NODE_ENV as string) === 'production'
        ? [
              'beta-admin.viafoura.com',
              'insights.viafoura.co',
              'insights-canary.viafoura.co',
          ]
        : ['beta-admin.viafoura.com', 'localhost:9090'];

// way to override the domain
export const DOMAIN_OVERRIDE = undefined;
