import fetchMetaTag from './fetch-meta-tag';
import { CUSTOM_ALLOW_LIST, DOMAIN_OVERRIDE } from '../../utils/constants';
import { DOMAIN_TAGS } from './meta-tags-constants';

/**
 * This function has 3 options.
 * 1. Looks to see if there are any domain meta tags.
 * If there are it gets the url from the first one it finds ( if it is not on the whitelist)
 * 2. If there are no meta tags it checks if there is a set customer url
 * 3. If the first two options are false then it just sets the domain as the hostname
 * @function getDomain
 * @return {string} returns domain url
 */

export default function getDomain(): string {
    let domain;
    const url = new URL(window.location.href);
    if (!CUSTOM_ALLOW_LIST.includes(url.host)) {
        domain = fetchMetaTag(DOMAIN_TAGS);
    }
    // adding domain here again incase fetchMetaTag() comes back undefined
    domain = domain || DOMAIN_OVERRIDE || url.hostname;

    return domain;
}
