import fetchApiData from '../../utils/fetch-api-data';
import getSessionId from './get-session-id';
import fetchMetaTag from './fetch-meta-tag';
import { SECTION_TAGS } from './meta-tags-constants';
import { BOOTSTRAP_URL } from './analytics-urls';
import { BootstrapApiData, BootstrapApi } from './bootstrap-types';

/**
 * This function is used to make a HTTP request using the Bootstrap API
 * Sets fetch method as post and sends a payload with a section name (if available)
 * @async
 * @function bootstrapApi
 * @return {Promise<BootstrapApi>} returns response data
 */
export default async function bootstrapApi(): Promise<
    BootstrapApiData | undefined
> {
    let data;
    let res: BootstrapApi;
    const sessionId = getSessionId();
    const section = fetchMetaTag(SECTION_TAGS);
    const payload = {
        section,
        section_tree: section,
        // bootstrap fails if this is empty string so conditionally rendering it
        ...(sessionId && { session: sessionId }),
    };

    try {
        res = await fetchApiData<BootstrapApi>(BOOTSTRAP_URL, {
            method: 'post',
            body: JSON.stringify(payload),
        });
        data = res.result;
    } catch (e) {
        console.error(e);
    }

    return data;
}
