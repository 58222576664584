import type { MetaData } from './analytics-types';
import parseLocationTracker from './parse-location-tracker';
import { BootstrapApiData } from './bootstrap-types';
import getLocationData from './get-location-data';
import toUuid from './to-uuid';

/**
 * This function builds the analytics meta data object
 * @function buildMetaData
 * @param {BootstrapApiData | undefined} bootstrapData - key value
 * @return {MetaData} Returns meta data object
 */
export default function buildMetaData(
    bootstrapData: BootstrapApiData | undefined,
): MetaData {
    // this does not include some events that are v2 specific (landingPage, pageUuid, pageStatus, page, page_date_created)
    // TODO: Confirm of page events and git hash needed
    const url = new URL(window.location.href);
    const ref = parseLocationTracker();
    const user = bootstrapData && bootstrapData.user;
    const location = getLocationData();
    const sectionTree = bootstrapData && bootstrapData.sectionTree;
    const site_id = localStorage.getItem('_vf_siteUUID');
    const section_id = sectionTree && sectionTree.section_id;

    return {
        domain: location.domain,
        site: site_id || undefined,
        section: section_id ? toUuid(section_id) : undefined,
        pageImage: location.image_url,
        ref,
        vf: Object.keys(ref).length > 0,
        url: location.url,
        path: location.path,
        title: location.title,
        user: user && user.id ? toUuid(user.id) : undefined,
        privilege:
            user && user.user_privilege ? user.user_privilege : undefined,
        page_type: location.page_type,
        page_description: location.description,
        topics: location.topics,
        amp: Boolean(url.searchParams.get('isAmpPage')),
        thirdparty_enabled:
            window.sessionStorage.getItem('vfThirdpartyCookiesEnabled') ===
            'true',
        container_id: location.container,
    };
}
