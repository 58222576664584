/**
 * Retrieves the users session id from a dom element
 * @function detectDOMSession
 * @return {String | null} returns session id or null
 */
export const detectDOMSession = (): string | null => {
    const platform = document.getElementById('viafoura-platform');
    return platform && platform.getAttribute('data-sid');
};

/**
 * Checks that the session string matches the given criteria
 * @function checkIfValidSessionId
 * @return {String} returns session string or empty string
 */
export const checkIfValidSessionId = (session: string): string =>
    session.length !== 0 && /^[a-z0-9]{16,32}$/i.test(session) ? session : '';

const SESSION_KEY = '_vf_sid';

/**
 * Retrieves the users session id from either a dom element or local storage
 * @function getSessionId
 * @return {String} returns session id
 */
export default function getSessionId(): string {
    let sessionId = '';
    const domSession = detectDOMSession();
    // id is set with a dispatcher in viafoura front when a user logs in
    const sessionKeyStored = window.localStorage.getItem(SESSION_KEY);
    if (domSession) {
        // TODO: Check if we even use this anymore?
        sessionId = checkIfValidSessionId(domSession);
    } else if (sessionKeyStored) {
        sessionId = checkIfValidSessionId(sessionKeyStored);
    }

    return sessionId;
}
