/**
 * Removes periods from the value passed and encodes it
 * @function encodeURIAndReplacePeriods
 * @param {string | number | boolean} value - value
 * @return {string} Returns encoded string
 */
export const encodeURIAndReplacePeriods = (
    value: string | number | boolean,
): string => encodeURIComponent(value).replace(/\./g, '%2E');

/**
 * Maps through each ref data point and writes in the correct string format
 * @function joinRefData
 * @param {Record<string, string>} ref - ref data
 * @return {Array<string>} Returns array for formatted strings
 */
export const joinRefData = (ref: Record<string, string>): Array<string> => {
    const refData = Object.entries(ref).map(
        ([key, value]) => `${key}=${encodeURIAndReplacePeriods(value)}`,
    );
    return refData;
};

/**
 * Takes the cookie data object and writes in the correct string format
 * @function stringifyCookieData
 * @param {Array<string>} keylist - key values
 * @param {T} data - cookie data
 * @return {string} Returns formatted string
 */
export default function stringifyCookieData<T>(
    keylist: Array<string>,
    data: T,
): string {
    const pairsArray = keylist.reduce(
        (prevValue: Array<string>, currKey: string) => {
            const value: unknown | string =
                data[currKey as keyof typeof data] || '';
            if (currKey === 'ref') {
                const refValue = joinRefData(
                    value as Record<string, string>,
                ).join('|');
                return prevValue.concat(refValue);
            }

            // TODO: Only object data is ref. We may want to add a check for object or array data eventually
            return prevValue.concat(
                encodeURIAndReplacePeriods(value as string | number | boolean),
            );
        },
        [],
    );
    return pairsArray.join('.');
}
