import { WIDGET_OPTIONS, WidgetNames } from './widget-constants';

enum EventListenerTypes {
    mousemove,
    touchstart,
    touchend,
    scroll,
}

export type EventListeners = keyof typeof EventListenerTypes;

/**
 * This function returns the vf element tag name or data-widget attribute value
 * If neither one is available it returns null
 * @function getElementName
 * @param {Element} - element
 * @return {string | null}
 */
export const getElementName = (element: Element): string | null => {
    let tag: string | null = element.tagName;
    if (!tag.match(/^vf-/i)) {
        tag = element.getAttribute(`data-widget`);
    }

    return tag;
};

const EVENT_TYPES: EventListeners[] = [
    'mousemove',
    'touchstart',
    'touchend',
    'scroll',
];

/**
 * This function adds the event listener for each value set in the EVENT_TYPES array
 * @function addEvtListeners
 * @param {function} - handler
 */
export const addEvtListeners = (handler: () => void): void => {
    EVENT_TYPES.forEach((type) => {
        document.addEventListener(type, handler);
    });
};

/**
 * This function removes the event listener for each value set in the EVENT_TYPES array
 * @function removeEvtListeners
 * @param {function} - handler
 */
export const removeEvtListeners = (handler: () => void): void => {
    EVENT_TYPES.forEach((type) => {
        document.removeEventListener(type, handler);
    });
};

/**
 * This function sets up event listeners events
 * @function renderEvtListener
 * @param {function} - bundle
 */
export const renderEvtListener = (bundle: () => void): void => {
    const handler = () => {
        bundle();
        removeEvtListeners(handler);
    };

    addEvtListeners(handler);
};

export const observerCallback = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
): void | null => {
    entries.forEach(({ intersectionRatio, target }) => {
        const elementName = getElementName(target);
        if (!elementName) return;

        const elementNameLowercase = elementName.toLowerCase() as WidgetNames;
        const element = WIDGET_OPTIONS[elementNameLowercase];
        const { loadFn, threshold } = element;
        const intersectingAtThreshold: boolean =
            intersectionRatio >= (threshold || 1.0);

        const isFloatingBell = target.hasAttribute('floating'); // adding because floating bell is alway at the top of the page

        if (element) {
            if (isFloatingBell) {
                renderEvtListener(loadFn);
                observer.unobserve(target);
            } else if (intersectingAtThreshold) {
                if (window.scrollY <= 0) {
                    renderEvtListener(loadFn);
                } else {
                    setTimeout(() => {
                        loadFn();
                    }, 1000);
                }
                observer.unobserve(target);
            }
        }
    });
};

/**
 * This function renders a new IntersectionObserver that tracks a given target element
 * The bundle code is loaded and the IntersectionObserver is removed when the following conditions are met
 * * 1. The targets intersection ratio is close to the elements specific threshold amount
 * * * a) If the target is at the top of the page then the bundle is load via an event listener
 * * * b) If the target is not at the top of the page then it loads right when the ratio is met
 * @function loadWidgetObserver
 * @return {IntersectionObserver}
 */
export default function loadWidgetObserver(): IntersectionObserver {
    const observer = new IntersectionObserver(observerCallback, {
        threshold: [0, 0.25, 0.5, 0.75, 1],
    });

    return observer;
}
