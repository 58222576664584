import { TrayTriggerInfo } from '../utils/widget-constants';
import injectStyleTag from '../utils/inject-style-tag';
import createBell from '../components/create-bell.js';
import BELL_STYLES from '../components/bell-styles.js';

export default function vfTrayTrigger(
    widgetInfo: TrayTriggerInfo,
    observer: IntersectionObserver,
): void {
    const { elements, notificationCount } = widgetInfo;
    const allBells: Element[] = [];

    elements.forEach((element: Element) => {
        const isFloating = element.hasAttribute('floating');
        // creating a new bell every time incase there are two different ones
        const bell = createBell(isFloating, notificationCount);
        const parent = element.closest<HTMLElement>('.viafoura');

        if (parent) {
            parent.appendChild(bell);
            allBells.push(bell);
        }

        observer.observe(element);
    });
    // injects styles for the bell just once
    injectStyleTag(BELL_STYLES);

    // removes the bell when the real tray trigger loads
    window.vfQ.push(function () {
        window.vf.$subscribe('vf-tray-trigger', 'loaded', function (): void {
            allBells.forEach((bell) => bell.remove());
        });
    });
}
