import { loadWidgets, widgetsOnPage } from './utils/load-widgets';
import { SKELETON_STYLES } from './utils/skeleton-styles';
import { initAnalytics } from './analytics/init-analytics';
import bootstrapApi from './analytics/utils/bootstrap-api';
import { ANALYTICS_URL } from './analytics/utils/analytics-urls';
import loadWidgetObserver from './utils/load-widget-observer';
import injectStyleTag from './utils/inject-style-tag';
import setSiteUUID from './analytics/utils/set-site-uuid';
import { commonBundle } from './utils/widget-constants';

// This overrides the cdn and analytics urls that are passed by Viafoura-Front for local testing
// They are always set to the release values on production, and as per the if clause the block
// will not run unless it was _not_ a production build (eg. local testing only).
//
// What this means is that when testing this locally, you can change `.env.local` to use
// any dev CDN you want, such as https://cdn.vf-dev2.net/
//
// When this is deployed, however, this block is not run. On the demo site we can configure
// the cdn_root through the CMS, and as such this does not need to run. In `widget-constants`,
// we actually use `window.vfConfig.cdn_root` to determine where to get `vf-v2.js` from, which
// is injected by the demo site.
if ((process.env.NODE_ENV as string) !== 'production') {
    window.vfConfig = {
        cdn_root: process.env.CDN_ROOT,
        analytics_url: ANALYTICS_URL,
    };
}
// setting this for the whole end point
window.vfQ = window.vfQ || [];

export async function deferLoad(): Promise<void> {
    async function init(): Promise<void> {
        injectStyleTag(SKELETON_STYLES);
        let bootstrapData;

        try {
            bootstrapData = await bootstrapApi();
            if (bootstrapData) {
                // incase there is no boostrap data returned
                setSiteUUID(bootstrapData);
                localStorage.setItem(
                    '_vf_bootstrap',
                    JSON.stringify(bootstrapData),
                );
            }
            // check if url has a deep link hash.
            if (window.location.hash.match(/^#vf-/)) {
                // if it does we just load the cdn
                commonBundle();
            } else {
                // if it doesn't we just load the entry point
                const observer = loadWidgetObserver();
                await loadWidgets(observer);
            }
        } catch (e) {
            console.error(e);
        }

        initAnalytics(bootstrapData, widgetsOnPage);
    }

    await init();
}
