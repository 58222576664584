/** Cookie Services
 * These are all helper functions for reading and writing basic cookies
 */

/**
 * This function that runs that toggles the decodeURI function
 * @function runDecodeUri
 * @param {string} uri the unsanitized uri
 * @param {boolean} decode flag to tell the function to decode the uri
 * @return {string} uri value
 */

export const runDecodeUri = (uri: string, decode?: boolean): string =>
    decode ? decodeURI(uri) : uri;

/**
 * This function calculates the age of a cookie in seconds.
 * The structure for a string passed is a number value and increment of time
 * It accepts the following increments of time:
 * Years (y), Days (d), Hours (g), Minutes (m), Seconds (s)
 * examples: 1y (1 year), 30d (30 days)
 * @function calculateMaxAge
 * @param {string | number} age string set in months or years
 * @return {number} uri value
 */
export const calculateMaxAge = (age: string | number): number => {
    const offsetMultiplier: { [key: string]: number } = {
        y: 31536000,
        d: 86400,
        h: 3600,
        m: 60,
        s: 1,
    };

    if (typeof age === 'string') {
        const scalar = parseInt(age.slice(0, -1), 10);
        const dimKey = age.slice(-1);
        return scalar * offsetMultiplier[dimKey] || 1;
    }

    return age;
};

/**
 * This function sets a cookie
 * @function writeCookie
 * @param {string} cname cookie name
 * @param {string} value data stored in the cookie
 * @param {number | string} maxAge cookies expiry data
 * @return {void} sets a cookie
 */

export const writeCookie = (
    cname: string,
    value: string,
    maxAge: number | string,
): void => {
    let cookie = `${cname}=${value};path=/`;
    if (maxAge) {
        const date = new Date();
        const calculatedAge = calculateMaxAge(maxAge);
        date.setTime(date.getTime() + calculatedAge * 1000);
        cookie += `;max-age=${calculatedAge};expires=${date.toUTCString()}`;
    }
    document.cookie = cookie;
};

/**
 * This function reads a cookie and returns the string
 * @function readCookie
 * @param {string} cname cookie name
 * @param {boolean} decode tells function if you want to decode values
 * @return {string} returns cookie value
 */

export const readCookie = (cname: string, decode?: boolean): string => {
    const name = `${cname}=`;
    const source = document.cookie;
    const cookies = source.split(';');
    for (let i = 0; i < cookies.length; i++) {
        // trim() removes the white space on the front of cookies after index 0 caused
        const cookie = runDecodeUri(cookies[i], decode).trim();
        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return '';
};
