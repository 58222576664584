import GetV2 from './GetV2';
import vfConversations from '../widgets/vf-conversations';
import vfConversationsCount from '../widgets/vf-conversations-count';
import vfTrayTrigger from '../widgets/vf-tray-trigger';
import vfTray from '../widgets/vf-tray';
import vfContentRecirculation from '../widgets/vf-content-recirculation';
import vfLivechat from '../widgets/vf-livechat';
import vfLivestory from '../widgets/vf-livestory';
import vfTopicFollow from '../widgets/vf-topic-follow';
import sharebar from '../widgets/sharebar';
import sharetotal from '../widgets/sharetotal';
import vfTrendingArticles from '../widgets/vf-trending-articles';
import vfConversationStarter from '../widgets/vf-conversation-starter';
import vfStandaloneAd from '../widgets/vf-standalone-ad';

export const WIDGET_NAMES = [
    'vf-conversations',
    'vf-conversations-count',
    'vf-tray-trigger',
    'vf-tray',
    'vf-content-recirculation',
    'vf-livechat',
    'vf-livestory',
    'vf-topic-follow',
    'sharetotal',
    'sharebar',
    'vf-trending-articles',
    'vf-conversation-starter',
    'vf-standalone-ad',
] as const;

// Added this incase we have more options down the line
export interface WidgetSettings {
    height: number;
    width?: number;
    threshold: number;
    skeletonTemplate?: string;
    loadFn: () => void;
    renderer: (
        widgetInfo: WidgetInfo | TrayTriggerInfo,
        observer: IntersectionObserver,
    ) => void;
    selector: string;
}

export type WidgetNames = typeof WIDGET_NAMES[number];

export interface WidgetInfo {
    elements: NodeListOf<Element>;
    options: WidgetSettings;
    name: WidgetNames;
}

export interface NotifcationCountType {
    notificationCount: number;
}

export interface TrayTriggerInfo extends WidgetInfo, NotifcationCountType {}

export type WidgetListType = Record<WidgetNames, WidgetSettings>;

// For testing purposes you can override the VF_V2_URL by setting
// window.vfConfig.cdn_root -- which is a key that is also used
// internally in viafoura-front. CDN_ROOT on env is always set.
const cdnRoot = window.vfConfig?.cdn_root || process.env.CDN_ROOT;

export const VF_V2_URL = `${cdnRoot}vf-v2.js`;

const V2Instance = new GetV2();
export const commonBundle = (): void => V2Instance.V2(VF_V2_URL);

export const WIDGET_OPTIONS: WidgetListType = {
    'vf-conversations': {
        selector: 'vf-conversations',
        height: 428,
        threshold: 0.25,
        skeletonTemplate: 'conversations',
        loadFn: () => {
            commonBundle();
        },
        renderer: vfConversations,
    },
    'vf-conversations-count': {
        selector: 'vf-conversations-count',
        height: 0,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: vfConversationsCount,
    },
    'vf-tray-trigger': {
        selector: 'vf-tray-trigger',
        height: 20,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: vfTrayTrigger as (
            widgetInfo: WidgetInfo | TrayTriggerInfo,
            observer: IntersectionObserver,
        ) => void,
    },
    'vf-tray': {
        selector: 'vf-tray',
        height: 40,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: vfTray,
    },
    'vf-content-recirculation': {
        selector: 'vf-content-recirculation',
        height: 140,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: vfContentRecirculation,
    },
    'vf-livechat': {
        selector: 'vf-livechat',
        height: 160,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: vfLivechat,
    },
    'vf-livestory': {
        selector: 'vf-livestory',
        height: 250,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: vfLivestory,
    },
    'vf-topic-follow': {
        selector: 'vf-topic-follow',
        height: 0,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: vfTopicFollow,
    },
    sharetotal: {
        selector: '[data-widget="sharetotal"]',
        height: 20,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: sharetotal,
    },
    sharebar: {
        selector: '[data-widget="sharebar"]',
        height: 40,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: sharebar,
    },
    'vf-trending-articles': {
        selector: 'vf-trending-articles',
        height: 144,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: vfTrendingArticles,
    },
    'vf-conversation-starter': {
        selector: 'vf-conversation-starter',
        height: 225,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: vfConversationStarter,
    },
    'vf-standalone-ad': {
        selector: 'vf-standalone-ad',
        height: 300,
        threshold: 1.0,
        loadFn: () => {
            commonBundle();
        },
        renderer: vfStandaloneAd,
    },
};
