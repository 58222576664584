import {
    WIDGET_OPTIONS,
    WidgetInfo,
    WidgetNames,
    TrayTriggerInfo,
} from './widget-constants';

import getNotificationCount from './get-notification-count';

export type WidgetModule = {
    bootstrap: (
        widgetInfo: WidgetInfo | TrayTriggerInfo,
        observer: IntersectionObserver,
    ) => void;
};

export const widgetsOnPage: WidgetNames[] = [];

export const findWidget = (widgetName: WidgetNames): NodeListOf<Element> => {
    const widgetSelector = WIDGET_OPTIONS[widgetName].selector;
    return document.querySelectorAll(widgetSelector);
};

export const findWidgets = (): WidgetInfo[] => {
    const widgets = Object.entries(WIDGET_OPTIONS).map(([widget, options]) => {
        const elements = findWidget(widget as WidgetNames);
        return {
            name: widget as WidgetNames,
            elements,
            options,
        };
    });

    return widgets;
};

const setParentHeight = (
    widgets: NodeListOf<Element>,
    height: number,
): void => {
    widgets.forEach((widget) => {
        const parent = widget.closest<HTMLElement>('.viafoura');
        const isFloating = widget.hasAttribute('floating');
        // checks to make sure it's the right parent element
        // it should be the one with the class viafoura
        if (parent && !parent.style.minHeight && !isFloating) {
            parent.setAttribute('style', `min-height: ${height}px`);
        }
    });
};

function injectContainerHeights(widgets: WidgetInfo[]): void {
    widgets.forEach((widget) => {
        const { elements, options } = widget;
        // find the parent elements and add the min-height to it
        setParentHeight(elements, options.height);
    });
}

export const loadWidgets = async (
    observer: IntersectionObserver,
): Promise<void> => {
    const widgets = findWidgets();
    injectContainerHeights(widgets);

    for await (const widget of widgets) {
        // Ignore widgets where there are no elements.
        if (!widget.elements.length) {
            continue;
        }

        widgetsOnPage.push(widget.name);

        try {
            const widgetInfo = {
                elements: widget.elements,
                options: widget.options,
                name: widget.name,
                ...(widget.name === 'vf-tray-trigger' && {
                    notificationCount: await getNotificationCount(),
                }),
            };

            widget.options.renderer(widgetInfo, observer);
        } catch (e: unknown) {
            // TODO: log this somewhere meaningful so we can track it
            console.error({
                message: `Could not load ${widget.name}`,
                originalError: e,
            });
            throw e;
        }
    }
};
