import { writeCookie } from '../../services/cookies';
import stringifyCookieData from './stringify-cookie-data';

interface CookieOptions {
    cname: string;
    maxAge: number | string;
}

export default function setTrackerCookie<T>(
    options: CookieOptions,
    data: T,
    keyList: Array<string>,
): void {
    const cvalue = stringifyCookieData<T>(keyList, data);
    const { cname, maxAge } = options;
    writeCookie(cname, cvalue, maxAge);
}
