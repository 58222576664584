import { runDecodeUri, readCookie } from '../../services/cookies';
import type { LocationTracker } from './analytics-types';

/**
 * This function checks if a key in a pair is ref
 * If it is ref we parse the values and return the ref object
 * @function checkIfRef
 * @param {string} key - key value
 * @param {string} value - value assigned to key
 * @return {LocationTracker | unknown} Returns ref object or value that was passed
 */
const checkIfRef = (key: string, value: string): LocationTracker | unknown => {
    if (key !== 'ref') {
        return runDecodeUri(value, true);
    }

    const split = value.split('|').map((val: string) => val.trim());
    const parsed = split.reduce(
        (prev: Record<string, string>, curr: string) => {
            const pair = curr.split('=').map((val: string) => val.trim());
            prev[pair[0]] = pair[1] ? decodeURI(pair[1]) : '';

            return prev;
        },
        {},
    );
    return parsed;
};

/**
 * This function takes cookie data and creates data object from it.
 * It is based on how viafoura front structures it's cookie data by separating it with periods(.)
 * @function createDataFromCookie
 * @param {Array<ReferrerKeyNames | ReturnKeyNames | VisitKeyNames>} keylist list of keys for data
 * @param {string} cookie cookie data
 * @return {T} Object made with cookie data
 */

export default function createDataFromCookie<T>(
    keylist: Array<string>,
    cname: string,
): T {
    const cookie = readCookie(cname);
    const cvalues = cookie.split('.');

    const cdata = keylist.reduce((acc, curr, index) => {
        if (cvalues[index]) {
            (acc as { [key: string]: any })[curr] = checkIfRef(
                curr,
                cvalues[index],
            );
        }
        return acc;
    }, {} as T);

    return cdata;
}
