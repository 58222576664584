/**
 * This checks if the passed tag exists in the dom
 * @function findTag
 * @param {string} tag meta tag name
 * @return {Element | undefined} returns found element or undefined
 */

const findTag = (tag: string): Element | null =>
    document.querySelector(`meta[name='${tag}']`) ||
    document.querySelector(`meta[property='${tag}']`);

/**
 * Looks at the DOM to see if there are any meta tags that match
 * an array list of tag names
 * @function fetchMetaTag
 * @param {Array<string>} tags meta tag name
 * @return {string} returns first meta tag found or an empty string
 */

export default function fetchMetaTag(tags: Array<string>): string {
    let tagContent = '';

    for (const tag of tags) {
        const foundTag = findTag(tag);
        if (foundTag) {
            tagContent = foundTag.getAttribute('content') || tagContent;
            break;
        }
    }

    return tagContent;
}
