// Format of a correct uuid pattern
const UUID_REGEX = new RegExp(
    '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
    'i',
);

/**
 * Checks if the given string follows the general UUID pattern
 * @function isUuid
 * @param {String} str - string to check whether it is a UUID or not
 * @return {Boolean} result of check
 */
export default function isUuid(str: string): boolean {
    return UUID_REGEX.test(str);
}
