/**
 * Creates a script tag with the passed src url and appends it to the head tag
 * @function injectScriptTag
 * @param {string} scriptSrc - src url
 */
export default function injectScriptTag(scriptSrc: string): void {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.setAttribute('src', scriptSrc);
    script.setAttribute('async', 'true');
    head.appendChild(script);
}
