const BELL_STYLES = `
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        src: local(''),
            url('https://cdn.viafoura.net/front/assets/fonts/roboto/roboto-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('https://cdn.viafoura.net/front/assets/fonts/roboto/roboto-regular.woff') format('woff'), /* Modern Browsers */
            url('https://cdn.viafoura.net/front/assets/fonts/roboto/roboto-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('https://cdn.viafoura.net/front/assets/fonts/roboto/roboto-regular.svg#Roboto') format('svg'); /* Legacy iOS */
    }
    .viafoura {
        font-size: 87.5%;
        line-height: 1.3;
    }
    .viafoura .vf-frontwise-tray-trigger {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 0;
        overflow: visible;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        border: none;
        word-break: keep-all;
        cursor: pointer;
        -webkit-appearance: button;
        text-transform: inherit;
        background: none;
        box-shadow: none;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
        vertical-align: baseline;
    }

    .viafoura .vf-frontwise-tray-trigger.vf-button {
        padding: 0;
        border-radius: 50%;
    }

    .viafoura .vf-frontwise-tray-trigger:focus,
    .viafoura .vf-frontwise-tray-trigger:hover,
    .viafoura .vf-frontwise-tray-trigger:active {
        color: #0074e8;
    }

    .viafoura .vf-frontwise-tray-trigger--floating {
        background-color: rgba(0,0,0,0.75);
        color: #fff;
        position: fixed;
        right: 30px;
        bottom: 30px;
        z-index: 10000004;
        margin: 1em;
    }

    .viafoura .vf-frontwise-tray-trigger--floating:focus,
    .viafoura .vf-frontwise-tray-trigger--floating:hover {
        background-color: #0074e8;
        color: #fff;
    }

    .viafoura .vf-frontwise-tray-trigger--floating:active {
        background-color: #004e9c;
    }


    .viafoura .vf-frontwise-tray-count {
        position: absolute;
        top: 10%;
        left: 50%;
        position: absolute;
        top: 10%;
        left: 50%;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        box-sizing: border-box;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 0.715em;
        line-height: 1;
        min-width: 1.24em;
        min-height: 1.24em;
        padding: 0.12em;
        border-radius: 0.62em;
        color: white;
        background-color: #cc4949;
    }

    .viafoura .vf-frontwise-tray-count--floating {
        top: 0;
        right: 0;
        left: auto;
        transform: translateX(25%);
        max-width: 1.8em;
        max-height: 1.8em;
        line-height: 0;
        min-width: 1.8em;
        min-height: 1.8em;
        padding: 0.4em;
        border-radius: 0.9em;
    }
    .viafoura .vf-frontwise-icon.vf-frontwise-icon-medium {
        width: 18px;
        height: 18px;
        font-size: 9px;
        line-height: 18px;
    }
    #fake-bell svg:not(:root) {
        overflow: hidden;
    }
    .viafoura .vf-frontwise-icon {
        display: inline-block;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
    }
`;

export default BELL_STYLES;
