import type { UserAgent } from './analytics-types';

/**
 * Gets user agent data to be used by for analytics data
 * @function getUserAgentData
 * @param {Window} w window object
 * @param {Document} d document object
 * @return {UserAgent} Returns user agent data
 */
export default function getUserAgentData(w: Window, d: Document): UserAgent {
    const { navigator, screen, innerWidth, innerHeight } = w;

    const { title, characterSet, documentElement } = d;

    return {
        // navigator default language
        nl: (navigator && navigator.language) || null,
        // navigator user agent
        nu: (navigator && navigator.userAgent) || null,
        // screen resolution
        sr: screen && screen.width ? `${screen.width}x${screen.height}` : null,
        // screen colorDepth
        sd: screen && screen.colorDepth ? screen.colorDepth : null,
        // viewport size
        vp: innerWidth ? `${innerWidth}x${innerHeight}` : null,
        // document title
        dt: title || null,
        // document encoding
        de: characterSet || null,
        // document language (<html lang="en">)
        dl:
            documentElement && documentElement.getAttribute
                ? documentElement.getAttribute('lang')
                : null,
    };
}
