import getDomain from './get-domain';
import fetchMetaTag from './fetch-meta-tag';
import fetchMetaTags from './fetch-meta-tags';
import {
    IMAGE_URL_TAGS,
    URL_TAGS,
    TITLE_TAGS,
    PAGE_TYPE_TAGS,
    DESCRIPTION_TAGS,
    KEYWORD_TAGS,
    TOPIC_TAGS,
    ARTICLE_TAG_TAGS,
    CONTAINER_ID_TAGS,
    SECTION_TAGS,
} from './meta-tags-constants';

export interface LocationData {
    domain: string;
    image_url: string;
    url: string;
    path: string;
    title: string;
    page_type: string;
    description: string;
    topics: Array<string>;
    container: string;
    section: string;
}

const COOKIES_TO_IGNORE = ['__vfz', '__vfsubscribed']; // ignores these search params

/**
 * Reduces character count to 500
 * @function reduceCharacters
 * @param {string} item string to be reduced
 * @return {string} reduced string
 */
export const reduceCharacters = (item: string): string => {
    return item.substring(0, 500);
};

/**
 * Removes white spaces
 * @function sanitizeString
 * @param {string} item string to be trimmed
 * @return {string} trimmed string
 */
export const sanitizeString = (item: string): string =>
    item.replace(/\s+/g, ' ').trim();

/**
 * Removes unwanted vf search params and returns a new url
 * @function sanitizeUrl
 * @param {string} href url string
 * @return {URL} Returns updated url
 */
export const sanitizeUrl = (
    href: string,
    ignoreList: Array<string> = [],
): URL => {
    const parsed = new URL(href);
    ignoreList.forEach((key) => {
        parsed.searchParams.delete(key);
    });
    return parsed;
};

/**
 * Removes unwanted vf search params and returns a new url
 * @function getUrl
 * @return {URL} Returns updated url
 */
export const getUrl = (): string =>
    sanitizeUrl(
        fetchMetaTag(URL_TAGS) || window.location.href,
        COOKIES_TO_IGNORE,
    ).href;

/**
 * Gets the path of the given url
 * @function getPath
 * @return {string} Returns string with the pathname and search params
 */
export const getPath = (): string => {
    const href = getUrl();
    const parsed = new URL(href);
    return `${parsed.pathname}${parsed.search}`;
};

/**
 * Finds the value of the title html tag
 * @function getTitleTag
 * @return {string} Content of the title tag
 */
export const getTitleTag = (): string => {
    const tag = document.querySelector('title');
    return (tag && tag.innerText) || '';
};

/**
 * Finds the values of the keywords, topics and article tag meta tags
 * @function getTopics
 * @return {Array<string>} Returns combined values found from the given meta tags
 */
export const getTopics = (): Array<string> => {
    const keywordTag = fetchMetaTag(KEYWORD_TAGS);
    let keywords: Array<string> = [];
    if (keywordTag) {
        // they only want the first one
        keywords = [...keywordTag.split(',').map((x) => x.trim())];
    }
    return [
        ...fetchMetaTags(TOPIC_TAGS),
        ...fetchMetaTags(ARTICLE_TAG_TAGS),
        ...keywords,
    ];
};

/**
 * Returns meta location data needed for meta analytics object
 * @function getLocationData
 * @return {LocationData} Returns location data
 */
export default function getLocationData(): LocationData {
    return {
        domain: getDomain(),
        image_url: sanitizeString(fetchMetaTag(IMAGE_URL_TAGS)),
        url: getUrl(),
        path: getPath(),
        title: reduceCharacters(
            sanitizeString(fetchMetaTag(TITLE_TAGS) || getTitleTag()),
        ),
        page_type: fetchMetaTag(PAGE_TYPE_TAGS),
        description: reduceCharacters(
            sanitizeString(fetchMetaTag(DESCRIPTION_TAGS)),
        ),
        topics: getTopics(),
        container: fetchMetaTag(CONTAINER_ID_TAGS),
        section: reduceCharacters(sanitizeString(fetchMetaTag(SECTION_TAGS))),
    };
}
