import visits from '../trackers/visits';
import returning from '../trackers/returning';
import referrer from '../trackers/referrer';
import getTimestamp from '../../utils/get-timestamp';
import type { MergedCookieData, MetaData } from './analytics-types';

/**
 * Gets all the data from the trackers
 * Creates a data object for analytics
 * @function getViewData
 * @param {MetaData} meta Metadata built for analytics
 * @return {MergedCookieData} Returns parsed cookie data
 */
export default function getViewData(meta: MetaData): MergedCookieData {
    const options = { ts: getTimestamp }; // new visit set by returning tracker
    const metaData = {
        domain: meta.domain,
        siteUuid: meta.site,
    };
    const defaultKeylist = ['domain', 'siteUuid'];

    // have to pass down options from each tracker because some values are added along the way
    const returningTracker = returning(metaData, options, defaultKeylist);
    const visitsTracker = visits(
        metaData,
        { ...options, ...returningTracker.options },
        defaultKeylist,
    );
    const referrerTracker = referrer(
        metaData,
        { ...options, ...visitsTracker.options },
        defaultKeylist,
    );
    const trackerData = {
        ...returningTracker.data,
        ...visitsTracker.data,
        ...referrerTracker.data,
    };
    return trackerData;
}
