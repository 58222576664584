import injectScriptTag from './inject-script-tag';

export default class GetV2 {
    scriptAdded: boolean;

    constructor() {
        this.scriptAdded = false;
    }

    V2(src: string): void {
        if (!this.scriptAdded) {
            try {
                injectScriptTag(src);
                this.scriptAdded = true;
            } catch (e) {
                console.log(e);
            }
        }
    }
}
