import { v4 as uuidv4 } from 'uuid';
import { WidgetNames } from '../utils/widget-constants';
import { ANALYTICS_URL } from './utils/analytics-urls';
import getViewData from './utils/get-view-data';
import buildMetaData from './utils/build-meta-data';
import getUserAgentData from './utils/get-user-agent-data';
import { mayWeTrack } from '@vf/consent-management';
import type {
    MergedCookieData,
    MetaData,
    UserAgent,
} from './utils/analytics-types';
import type { BootstrapApiData } from './utils/bootstrap-types';
interface ExtraAnalyticsData {
    event_type: string;
    engage?: object;
    action?: string;
    label?: string;
}

interface CommonData {
    // will need to add keys when adding new analytics events
    view: MergedCookieData;
    meta: MetaData;
    w: WidgetNames[];
    ua: UserAgent;
    rq: string;
}

export interface PayloadData extends ExtraAnalyticsData, CommonData {}

// keeping this on the outside so that we aren't running these every time we build a new payload
let meta: CommonData['meta'];
let view: CommonData['view'];
let ua: CommonData['ua'];
let w: CommonData['w']; // widgets
let rq: CommonData['rq']; //uniqueId

const canTrack = mayWeTrack(); // for permission to track analytics

/**
 * Creates payload object from all the gathered data
 * @function buildPayload
 * @param {ExtraAnalyticsData} data - extra data not in main payload data
 * @return {PayloadData} Returns payload object
 */
export function buildPayload(data: ExtraAnalyticsData): PayloadData {
    return {
        view,
        meta,
        ua,
        rq,
        w,
        ...data,
    };
}

/**
 * Creates a pixel image that records the analytics data
 * @function embedTrackingImage
 * @param {PayloadData} data - payload object
 * @return {HTMLImageElement} Returns a image element
 */
export const embedTrackingImage = (data: PayloadData): HTMLImageElement => {
    const img = new Image();
    img.src = `${ANALYTICS_URL}?t=${encodeURIComponent(JSON.stringify(data))}`;
    return img;
};

/**
 * Generic function used by different ingest events to create the pixel image
 * Custom event data can also be passed to the payload here
 * @function trackGenericEvent
 * @param {ExtraAnalyticsData} data - extra data not in main payload data
 */
export const trackGenericEvent = (data: ExtraAnalyticsData): void => {
    if (canTrack) {
        const payload = buildPayload(data);
        embedTrackingImage(payload);
    }
};

/**
 * Creates the View Lite Ingest event
 * @function trackViewLiteEvent
 */
export function trackViewLiteEvent(): void {
    trackGenericEvent({
        event_type: 'analytics.view_lite',
    });
}

/**
 * Creates the Engage Lite Ingest event to track basic active time on page
 * @function trackViewLiteEvent
 */
export function trackEngageLiteEvent(): void {
    let startDate = new Date();
    let elapsedTime = 0;
    let isMainCodeLoaded = false;
    const focus = function () {
        startDate = new Date();
    };
    const blur = function () {
        const endDate = new Date();
        const spentTime = endDate.getTime() - startDate.getTime();
        elapsedTime += spentTime;
    };

    const mainVFcodeLoaded = function () {
        // if main VF code is loaded - fire the time spent and stop tracking it
        beforeunload();
        isMainCodeLoaded = true;
    };

    const beforeunload = function () {
        if (isMainCodeLoaded) {
            return;
        }
        const endDate = new Date();
        const spentTime = endDate.getTime() - startDate.getTime();
        elapsedTime += spentTime;

        trackGenericEvent({
            event_type: 'analytics.engage_lite',
            engage: { time: elapsedTime },
        });
    };

    window.addEventListener('focus', focus);
    window.addEventListener('blur', blur);
    window.addEventListener('beforeunload', beforeunload);
    window.addEventListener('vf-main-loaded', mainVFcodeLoaded);
}

/**
 * Creates the Comment Counter Tracking event tot track clicks on comment counter
 * @function trackViewLiteEvent
 */
export function trackCommentCounterClickEvent(): void {
    let isMainCodeLoaded = false;
    window.addEventListener('vf-main-loaded', () => {
        isMainCodeLoaded = true;
    });
    const commentCounter =
        document.querySelector('.vf-comments-count') ||
        document.querySelector('.vf-comments-count-custom');

    if (!commentCounter || commentCounter.nodeType !== 1) {
        return;
    }
    let commentCounterLink = commentCounter;
    if (commentCounter.tagName.toLowerCase() !== 'a') {
        const link = commentCounter.closest('a');
        if (!link || link.nodeType !== 1) {
            return;
        }
        commentCounterLink = link || commentCounter;
    }

    commentCounterLink.addEventListener('click', () => {
        if (isMainCodeLoaded) {
            return;
        }
        trackGenericEvent({
            event_type: 'analytics.interaction',
            action: 'click',
            label: 'vf-comments-count',
        });
    });
}

/**
 * Assigns the values to all the different variables used in the payload
 * @function setAnalyticsValues
 * @param {BootstrapApiData} bootstrapApi - bootstrap api call data
 */
export const setAnalyticsValues = (
    bootstrapApi: BootstrapApiData | undefined,
    widgets: CommonData['w'],
): void => {
    meta = buildMetaData(bootstrapApi);
    view = getViewData(meta);
    ua = getUserAgentData(window, document);
    rq = uuidv4();
    w = widgets;

    // pass view and rq data to regular analytics code
    if (view) {
        localStorage.setItem(
            '_vf_skeleton_view_analytics',
            JSON.stringify(view),
        );
    }

    if (rq && meta.url) {
        const rqData = {
            rq,
            path: meta.path,
            t: Date.now(),
        };
        localStorage.setItem('_vf_rq_info_analytics', JSON.stringify(rqData));
    }
};

/**
 * Init function for running all the analytics events
 * @function initAnalytics
 * @param {BootstrapApiData} bootstrapApi - bootstrap api call data
 */
export const initAnalytics = (
    bootstrapApi: BootstrapApiData | undefined,
    widgets: CommonData['w'],
): void => {
    if (canTrack) {
        setAnalyticsValues(bootstrapApi, widgets);
        trackViewLiteEvent();
        trackEngageLiteEvent();
        trackCommentCounterClickEvent();
    }
};
