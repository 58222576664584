export const SKELETON_STYLES = `
/* Skeleton Renderer styles */

#vf-skeleton-container {
    margin: 20px 0;
    overflow: auto;
    width: 100%;
}

.vf-skeleton-generic-block {
    width: 100%;
    background: #f6f7f8;
    background-image: linear-gradient(to right, #EDEDED 0%, #F3F3F3 20%, #EDEDED 40%, #EDEDED 100%);
    animation: shimmer 0.5s linear forwards infinite;
    background-size: 1440px 104px;
    border-radius: 4px;
}

@-webkit-keyframes shimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
}

.vf-skeleton-conversations-container .vf-skeleton-generic-block:not(:first-child) {
    margin-top: 8px;
}

.vf-skeleton-comments-container {
    height: 112px;
    margin-top: 28px;
}

.vf-skeleton-comments-img-wrapper {
    width: 46px;
    float: left;
}

.vf-skeleton-comments-img-wrapper .vf-skeleton-generic-block {
    border-radius: 100%;
}

.vf-skeleton-comments-line-wrapper {
    float: left;
    width: 80%;
    margin-left: 14px;
}

.vf-skeleton-comments-line-wrapper .vf-skeleton-generic-block:nth-child(1) {
    width: 45%
}

.vf-skeleton-comments-line-wrapper .vf-skeleton-generic-block:nth-child(4) {
    width: 55%
}

@media (min-width: 757px) {
    .vf-skeleton-comments-line-wrapper {
        width: 90%;
    }

    .vf-skeleton-comments-line-wrapper .vf-skeleton-generic-block:nth-child(1) {
        width: 20%
    }

    .vf-skeleton-comments-line-wrapper .vf-skeleton-generic-block:nth-child(4) {
        width: 80%
    }
}`;
