import fetchApiData from './fetch-api-data';
import TAllNotifications from './notifications-types';

export default async function getNotificationCount(): Promise<number> {
    let allNotifications: TAllNotifications;
    let totalCount = 0;
    let siteUUID = localStorage.getItem('_vf_siteUUID');

    if (!siteUUID) {
        siteUUID = '00000000-0000-4000-8000-000000000000'; // default uuid
    }

    try {
        allNotifications = await fetchApiData<TAllNotifications>(
            `${process.env.NOTIFICATIONS_ROOT}${siteUUID}/all`,
            {
                method: 'GET',
            },
        );

        if (!allNotifications) {
            // return 0 if no notifications found
            return totalCount;
        }

        const broadcastCount =
            allNotifications.broadcasts.length > 0
                ? allNotifications.broadcasts.filter(
                      (broadcast) => broadcast.status === 'new',
                  ).length
                : 0;

        const notificationCount =
            allNotifications.notifications.length > 0
                ? allNotifications.notifications
                      .map((notification) => notification.notifications)
                      .flat() // flattens all the notifications arrays into 1
                      .reduce((previousValue, currentValue) => {
                          if (currentValue.status === 'new') {
                              previousValue++;
                          }

                          return previousValue;
                      }, 0)
                : 0;

        totalCount = broadcastCount + notificationCount;
    } catch (e) {
        console.error(e);
    }

    return totalCount;
}
