import type { LocationTracker } from './analytics-types';
import isUuid from './is-uuid';

/**
 * Returns the trackers sharer_uuid if it passes isUuid function check
 * @function getSharerUuid
 * @param {LocationTracker} tracker - location tracker results
 * @return {String} sharer id or empty string
 */
export default function getSharerUuid(tracker: LocationTracker): string {
    if (tracker && isUuid(tracker.sharer_uuid)) {
        return tracker.sharer_uuid;
    }
    return '';
}
