/**
 * Creates a style tag with the passed css values and appends it to the head tag
 * @function injectStyleTag
 * @param {string} css - css values in a string
 */

export default function injectStyleTag(css: string): void {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.innerHTML = css;
    head.appendChild(style);
}
