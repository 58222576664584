import setAttributes from '../utils/set-attributes';

/**
 * Creates a fake bell button component to replace the vf-tray-trigger bell buttons
 * @function createBell
 * @param {boolean} isFloating - if it's a floating bell or static
 * @param {number} notificationCount - number of notifications
 */
const createBell = (
    isFloating: boolean,
    notificationCount: number,
): HTMLElement => {
    const bell = document.createElement('button');
    const triggerClass = isFloating
        ? 'floating vf-tray-trigger--floating vf-frontwise-tray-trigger--floating'
        : '';

    const buttonAttrs = {
        type: 'button',
        'aria-label': 'View your notifications and profile settings',
        'data-vf-id': '-1',
        class: `fake-bell vf-button vf-no-outline is-size-large vf-tray-trigger vf-frontwise-tray-trigger ${triggerClass}`,
        ...(isFloating && { 'data-testid': 'floating-bell' }),
    };

    setAttributes(bell, buttonAttrs);

    // show 9+ if higher than 9 notifications
    const count: string | number =
        notificationCount > 9 ? '9+' : notificationCount;

    // will only rendering if the count is higher than 0
    const notificationBubble = `
        <strong
        aria-label="You have ${notificationCount} new notifications"
        data-testid="notification-bubble"
        class="vf-label count-badge count-badge--small count-badge--danger-color vf-frontwise-tray-count vf-tray-count ${
            isFloating
                ? 'vf-frontwise-tray-count--floating vf-tray-count--floating'
                : ''
        }"
    >
            <span data-testid="notification-bubble-text" aria-hidden="true" class="count-badge__inner count-badge--small__inner">
                ${count}
            </span>
        </strong>
    `;

    bell.innerHTML += `
        ${notificationCount > 0 ? notificationBubble : ''}
        <svg  viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" class="vf-icon-medium vf-icon vf-frontwise-icon-medium vf-frontwise-icon vf-icon_bell_icon">
            <!---->
            <!---->
            <defs></defs>
            <g>
                <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M50 3c7 0 13 3 18 7 4 5 7 11 8 18l5 28c2 7 5 10 9 12l3 3c1 1 2 2 2 5 0 2-2 5-5 5H10c-3 0-5-3-5-5 0-3 1-4 2-5l3-3c4-2 7-5 9-12l5-28c1-7 4-13 8-18 5-4 11-7 18-7zM36 83h28v1c0 8-6 14-14 14s-14-6-14-14v-1z"
                ></path>
            </g>
        </svg>
    `;

    return bell;
};

export default createBell;
