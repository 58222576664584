import { renderSkeleton } from './skeleton-renderer';
import { WidgetInfo } from './widget-constants';

/**
 * This function renders the skeleton version of the given widget
 * It also attaches a IntersectionObserver to it so that when the widget
 * is in view we load the needed scripts and remove the skeleton
 * @function widgetRenderer
 * @param {WidgetInfo} widgetInfo All of the widget details
 * @param {SkeletonOptions} skeletonOpts All of the details for the skeleton
 * @param {Function} loadFn Function the runs the needed scripts when
 * the IntersectionObserver requirements are met
 */
export default function widgetRenderer(
    widgetInfo: WidgetInfo,
    observer: IntersectionObserver,
): void {
    /*
     ** setting a min-height for the widget container
     ** you pass this to renderSkeleton() as well to ensure it is the min-height you need
     ** we store the height in a const file for now but it will be passed as options eventually
     ** The height is a required field
     */
    const { elements, options } = widgetInfo;
    const copiedOptions = Object.assign({}, options); // don't want to mutate original options so we make a copy

    elements.forEach((el) => {
        /*
         ** This checks if there is a set min-height on the parent
         ** If there is it replaces the options default min-height
         */
        const parent = el.closest<HTMLElement>('.viafoura');
        if (parent && parent.style.minHeight) {
            copiedOptions.height = parseInt(parent.style.minHeight);
        }
        el.appendChild(renderSkeleton(copiedOptions));
        observer.observe(el);
    });
}
