import parseLocationTracker from './parse-location-tracker';
import type {
    TrackerMetaData,
    LocationTracker,
} from '~/analytics/utils/analytics-types';
import getSharerUuid from './get-sharer-uuid';

/**
 * This function returns a referrer object based on page meta data or parseLocationTracker() data
 * @function getReferrer
 * @param {TrackerMetaData} data - values set from page meta data
 * @return {LocationTracker} Returns referrer object
 */
export default function getReferrer(data: TrackerMetaData): LocationTracker {
    const locationTracker = parseLocationTracker();

    const sharer_uuid = getSharerUuid(locationTracker);

    const terms = ''; // this value never seems to change either. Not sure what it's used for?

    /* Some kind of article share (sharebar, email, tray or push) */
    if (locationTracker && locationTracker.medium) {
        return {
            medium: locationTracker.medium || '',
            source: locationTracker.source || '',
            sharer_uuid,
            terms,
        };
    }
    /* outside referral */
    if (document.referrer && !document.referrer.includes(data.domain)) {
        return {
            medium: 'referral',
            source: document.referrer,
            sharer_uuid,
            terms,
        };
    }

    /* direct referral... */
    return {
        medium: 'direct',
        source: '',
        sharer_uuid,
        terms,
    };
}
