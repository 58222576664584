import type { LocationTracker } from './analytics-types';

/**
 * Returns any vf related hash values
 * @function parseHashCommentId
 * @return {number | false} sharer id or false
 */
export const parseHashCommentId = (): boolean | number => {
    const text = window.location.hash;
    const search = /^#vf-(\d+)/;
    const match = search.exec(text);
    // make sure match exists, and it's a number
    if (match && match.length > 1) {
        const number = parseInt(match[1], 10);
        if (typeof number === 'number') {
            return number;
        }
    }
    return false;
};

/**
 * Returns any vf related hash values from the domains url
 * @function parseLocationTracker
 * @return {LocationTracker} the location tracker info
 */
export default function parseLocationTracker(): LocationTracker {
    const uri = new URL(window.location.href);
    const vfz = uri.searchParams.get('__vfz');
    const res = {} as LocationTracker;

    if (vfz) {
        const pieces = vfz.split('|');

        for (let i = 0; i < pieces.length; i++) {
            const piece = decodeURIComponent(pieces[i]);
            const kv = piece.split('=');
            res[kv[0]] = kv[1];
        }
    }

    const shareCommentId = parseHashCommentId();

    if (shareCommentId) {
        res.comment_share = shareCommentId as number;
    }

    return res;
}
