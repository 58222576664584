import { BootstrapApiData } from './bootstrap-types';

export const SECTION_ID_STORAGE_KEY = '_vf_siteUUID';

export default function setSiteUUID(bootstrapData: BootstrapApiData): void {
    const settings = bootstrapData.settings;
    const site_uuid = settings && settings.site_uuid;

    if (site_uuid) {
        localStorage.setItem(SECTION_ID_STORAGE_KEY, site_uuid);
    }
}
