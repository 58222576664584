import { v4 as uuidv4 } from 'uuid';
import createDataFromCookie from '../utils/create-data-from-cookie';
import setTrackerCookie from '../utils/set-tracker-cookie';
import type {
    TrackerMetaData,
    TrackerOptions,
    VisitData,
    TrackerReturn,
} from '../utils/analytics-types';
interface VisitReturn extends TrackerReturn {
    data: VisitData;
}

/**
 * This function returns visitor data based on the set _vfa cookie
 * If the _vfa cookie is not set it returns default data
 * It also returns options that are passed to other trackers
 * @function visits
 * @param {TrackerMetaData} metaData values set from meta data
 * @param {TrackerOptions} options values set from meta data
 * @param {Array<string>} keyList default keys for returned data object
 * @return {TrackerReturn} returns referrer data and passed options from other trackers
 */
export default function visits(
    metaData: TrackerMetaData,
    options: TrackerOptions,
    keyList: Array<string>,
): VisitReturn {
    const { newVisit, ts } = options;
    const defaultValues = {
        ...metaData,
        uniqueId: uuidv4(),
        firstVisit: ts,
        previousVisit: ts,
        currentVisit: ts,
        visitCount: 1,
    };
    const updatedKeyList = [
        ...keyList,
        'uniqueId',
        'firstVisit',
        'previousVisit',
        'currentVisit',
        'visitCount',
    ];

    let data = createDataFromCookie<VisitData>(updatedKeyList, '_vfa');
    if (updatedKeyList.some((key) => !data[key as keyof VisitData])) {
        data = defaultValues;
    } else if (newVisit) {
        data.previousVisit = data.currentVisit;
        data.currentVisit = ts;
        data.visitCount++;
    }

    setTrackerCookie<VisitData>(
        { cname: '_vfa', maxAge: '1y' },
        data,
        updatedKeyList,
    );

    return { options, data };
}
