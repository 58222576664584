export const DOMAIN_TAGS = [
    'vf:domain',
    'vf:description',
    'og:domain',
    'domain',
];
export const SECTION_TAGS = ['vf:section'];
export const IMAGE_URL_TAGS = [
    'vf:image',
    'og:image',
    'og:image:secure_url',
    'og:image:url',
    'twitter:image',
];
export const URL_TAGS = ['vf:url', 'og:url'];
export const TITLE_TAGS = ['vf:title', 'og:title', 'twitter:title'];
export const PAGE_TYPE_TAGS = ['vf:type', 'og:type'];
export const DESCRIPTION_TAGS = [
    'vf:description',
    'og:description',
    'twitter:description',
    'description',
];
export const KEYWORD_TAGS = ['keywords'];
export const TOPIC_TAGS = ['vf:topic'];
export const ARTICLE_TAG_TAGS = ['article:tag'];
export const CONTAINER_ID_TAGS = ['vf:container_id'];
