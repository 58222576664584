import createDataFromCookie from '../utils/create-data-from-cookie';
import setTrackerCookie from '../utils/set-tracker-cookie';
import type {
    TrackerMetaData,
    TrackerOptions,
    ReturnData,
    TrackerReturn,
} from '../utils/analytics-types';
interface ReturningReturn extends TrackerReturn {
    data: ReturnData;
}

/**
 * This function returns returning visitor data based on the set _vfb cookie
 * If the _vfb cookie is not set it returns default data
 * It also returns options that are passed to other trackers plus the newVisit option if there is no sessionStart data
 * @function returning
 * @param {TrackerMetaData} metaData values set from meta data
 * @param {TrackerOptions} options values set from meta data
 * @param {Array<string>} keyList default keys for returned data object
 * @return {TrackerReturn} returns referrer data and passed options from other trackers
 */
export default function returning(
    metaData: TrackerMetaData,
    options: TrackerOptions,
    keyList: Array<string>,
): ReturningReturn {
    const { ts } = options;
    const defaultValues = {
        ...metaData,
        pageViews: 1,
        /* no docs on what this does and it doesn't seem to change */
        outgoing: 10,
        sessionStart: ts,
        isRecirculation: false,
    };
    const updatedKeyList = [
        ...keyList,
        'pageViews',
        'outgoing',
        'sessionStart',
        'isRecirculation',
        'landingPage', // v2
        'page_description', // v2
        'page_title', // v2
    ];
    let data = createDataFromCookie<ReturnData>(updatedKeyList, '_vfb');
    let updatedOptions = options;

    if (!data.sessionStart) {
        data = defaultValues;
        updatedOptions = { ...options, ...{ newVisit: true } };
    } else {
        data.pageViews++;
    }
    // If this is an internal referring, then it is recirculation
    if (document.referrer && document.referrer.includes(data.domain)) {
        data.isRecirculation = true;
    }

    setTrackerCookie<ReturnData>(
        { cname: '_vfb', maxAge: '30m' },
        data,
        updatedKeyList,
    );

    return { options: updatedOptions, data };
}
