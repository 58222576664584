/**
 * Sets multiple attributes for an element
 * @function setAttributes
 * @param {Element} el - Element to add the attributes to
 * @param {object} attrs - Attribute keys and there values
 */
export default function setAttributes(
    el: Element,
    attrs: Record<string, string>,
): void {
    for (const key in attrs) {
        el.setAttribute(key, attrs[key]);
    }
}
