import {
    CONVERSATION_LRG_BLOCK_HEIGHT,
    CONVERSATION_SM_BLOCK_HEIGHT,
} from './skeleton-constants';

import { WidgetSettings } from './widget-constants';

export function renderGenericBlock(height?: string): HTMLElement {
    const block = document.createElement('div');
    block.setAttribute('class', 'vf-skeleton-generic-block');
    block.setAttribute('data-testid', 'generic-block');
    block.setAttribute('style', `height: ${height || '100%'}`);

    return block;
}

export function renderConversationsHeader(): HTMLElement {
    const headerContainer = document.createElement('div');
    headerContainer.append(
        renderGenericBlock(CONVERSATION_LRG_BLOCK_HEIGHT),
        renderGenericBlock(CONVERSATION_SM_BLOCK_HEIGHT),
    );

    return headerContainer;
}

export function renderConversationsComment(): HTMLElement {
    const commentContainer = document.createElement('div');
    commentContainer.setAttribute('class', 'vf-skeleton-comments-container');
    commentContainer.setAttribute('data-testid', 'comments-container');

    const imageWrapper = document.createElement('div');
    imageWrapper.setAttribute('class', 'vf-skeleton-comments-img-wrapper');
    imageWrapper.setAttribute('data-testid', 'image-wrapper');
    imageWrapper.appendChild(renderGenericBlock('46px'));

    const commentLinesWrapper = document.createElement('div');
    commentLinesWrapper.setAttribute(
        'class',
        'vf-skeleton-comments-line-wrapper',
    );
    commentLinesWrapper.setAttribute('data-testid', 'comments-line-wrapper');

    const commentLine = renderGenericBlock('20px');
    commentLinesWrapper.append(
        commentLine,
        commentLine.cloneNode(true),
        commentLine.cloneNode(true),
        commentLine.cloneNode(true),
    );
    commentContainer.append(imageWrapper, commentLinesWrapper);

    return commentContainer;
}

export function renderConversationsSkeleton(height: number): HTMLElement {
    const container = document.createElement('div');
    container.setAttribute('class', 'vf-skeleton-conversations-container');
    container.setAttribute('data-testid', 'conversations-container');
    const commentBlockCount = Math.floor((height - 138) / 140);

    container.appendChild(renderConversationsHeader());

    for (let i = 0; i < commentBlockCount; i++) {
        container.appendChild(renderConversationsComment());
    }

    return container;
}

export function renderSkeleton(options: WidgetSettings): HTMLElement {
    const { height, skeletonTemplate } = options;
    const container = document.createElement('div');
    container.setAttribute('id', 'vf-skeleton-container');
    container.setAttribute('style', `height: ${height}px`);
    container.setAttribute('data-testid', 'skeleton-container');
    container.style.height = `${height}px`;

    if (height === 0) {
        container.style.margin = `0px`;
    }

    switch (skeletonTemplate) {
        case 'conversations':
            container.appendChild(renderConversationsSkeleton(height));
            break;
        default:
            container.appendChild(renderGenericBlock());
    }

    return container;
}
