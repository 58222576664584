const commonOptions = {
    credentials: 'include',
};

/**
 * This function is used to make a HTTP request using the Fetch API
 * @async
 * @function fetchApiData
 * @param {string} url This is the url of the api you want the data from
 * @param {object} options Fetch API options
 * @return {Promise<T>} returns response data
 * @throws {Error} throws error if no response from url
 */
export default async function fetchApiData<T>(
    url: string,
    options?: { [key: string]: any },
): Promise<T> {
    try {
        const finalOptions = Object.assign(
            {},
            commonOptions,
            options,
        ) as RequestInit;
        const response = await fetch(url, finalOptions);
        const json = (await response.json()) as T;
        // if there is a successful response
        return json;
    } catch (e) {
        throw new Error(
            `Unable to fetch a response from ${url} the server responded with the following error: ${
                (e as Error).message
            }`,
        );
    }
}
