/**
 * Formats the passed id to match the VF format
 * @function toUuid
 * @param {string | number} id - pass id to be formatted
 * @return {string} Returns the formatted id
 */
export default function toUuid(id: string | number): string {
    const UUID_REGEX = new RegExp(
        '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
        'i',
    );
    const VFID_MAX = 4503599627370495;
    const VF_UUID_PREFIX = '00000000-0000-4000-800';

    if (UUID_REGEX.test(id as string)) {
        return id as string;
    }
    if (!Number.isInteger(id) || id < 0 || id > VFID_MAX) {
        throw new TypeError(`invalid argument: ${id}`);
    }

    const hexId = id.toString(16);

    /* pad-left with 0s until total length is 13 - note that Array(1).join('0') === '' */
    const totalLengthBound = 14;
    const paddedHexId =
        Array(totalLengthBound - hexId.length).join('0') + hexId;
    return `${VF_UUID_PREFIX}${paddedHexId[0]}-${paddedHexId.substring(1)}`;
}
