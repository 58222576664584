import createDataFromCookie from '../utils/create-data-from-cookie';
import {
    TrackerMetaData,
    TrackerOptions,
    ReferrerData,
    TrackerReturn,
} from '../utils/analytics-types';
import getReferrer from '../utils/get-referrer';
import getSharerUuid from '../utils/get-sharer-uuid';
import isEqual from '../../utils/is-equal';
import parseLocationTracker from '../utils/parse-location-tracker';
import setTrackerCookie from '../utils/set-tracker-cookie';
interface ReferrerReturn extends TrackerReturn {
    data: ReferrerData;
}

/**
 * This function returns referrer data based on the set _vfz cookie
 * If the _vfz cookie is not set it returns default data
 * It also returns options that are passed to other trackers
 * @function referrer
 * @param {TrackerMetaData} metaData values set from meta data
 * @param {TrackerOptions} options values set from meta data
 * @param {Array<string>} keyList default keys for returned data object
 * @return {TrackerReturn} returns referrer data and passed options from other trackers
 */
export default function referrer(
    metaData: TrackerMetaData,
    options: TrackerOptions,
    keyList: Array<string>,
): ReferrerReturn {
    const { newVisit, ts } = options;
    const ref = getReferrer(metaData);
    const defaultValues: ReferrerData = {
        ...metaData,
        referrerStart: ts,
        refVisitCount: 1,
        ref,
    };
    const updatedKeyList = [
        ...keyList,
        'referrerStart',
        'refVisitCount',
        'ref',
    ];

    let data = createDataFromCookie<ReferrerData>(updatedKeyList, '_vfz');

    const locationTracker = parseLocationTracker();

    const hasMediumChanged =
        data.ref && locationTracker.medium !== data.ref.medium;
    const hasSharerChanged =
        data.ref && getSharerUuid(locationTracker) !== getSharerUuid(data.ref);
    const hasReferrerChanged =
        data.ref &&
        document.referrer &&
        !document.referrer.includes(data.domain);

    if (!data.referrerStart) {
        data = defaultValues;
    } else if (newVisit) {
        if (ref.medium === 'referral' && !isEqual(ref, data.ref)) {
            data = defaultValues;
        } else {
            data.refVisitCount++;
        }
    } else if (hasMediumChanged || hasSharerChanged || hasReferrerChanged) {
        data.ref = ref;
    }

    setTrackerCookie<ReferrerData>(
        { cname: '_vfz', maxAge: '6m' },
        data,
        updatedKeyList,
    );

    return { options, data };
}
