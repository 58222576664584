/**
 * checks if the value is an object
 * @function isObject
 * @param {*} obj - value to be checked
 * @return {boolean} whether it is a object
 */
export const isObject = (obj: unknown): boolean => {
    const t = typeof obj;
    return obj !== null && (t === 'object' || t === 'function');
};

/**
 * Compares if two values are equal
 * @function isEqual
 * @param {Record<string, unknown> | Array<unknown> | unknown} a - first item
 * @param {Record<string, unknown> | Array<unknown> | unknown} b - second object
 * @return {boolean} whether they are equivalent or not
 */
export default function isEqual(
    a: Record<string, unknown> | Array<unknown> | unknown,
    b: Record<string, unknown> | Array<unknown> | unknown,
): boolean {
    /* if a is an array, b must also be an array of the same length, with pairwise isEqual elements to a */
    if (Array.isArray(a)) {
        if (!Array.isArray(b) || a.length !== b.length) {
            return false;
        }
        return a.every((elem, idx) => isEqual(elem, b[idx]), true);
    }
    /**
     *  if a is a non-null object (and not an array), b must also be a non-array non-null object with the same amount of keys
     *  as a, where the pairwise keys are isEqual
     */
    if (isObject(a)) {
        if (!isObject(b) || Array.isArray(b)) {
            return false;
        }
        const a_keys = Object.keys(a as Record<string, unknown>).sort();
        const b_keys = Object.keys(b as Record<string, unknown>).sort();
        return (
            isEqual(a_keys, b_keys) &&
            a_keys.every((key) =>
                isEqual(
                    (a as Record<string, unknown>)[key],
                    (b as Record<string, unknown>)[key],
                ),
            )
        );
    }
    /* since a is neither a non-null object nor an array, it must be a primitive value and so we can use === against b */
    return a === b;
}
